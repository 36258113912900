import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import SummaryApi from '../../common';
import BeginnerBarberBook from '../../assets/Beginner Barber Book.png';
import FundamentalsBook from '../../assets/Fundamentals Book.png';
import ModuleBook from '../../assets/Module Book.png';

const BookList = () => {
    const [books, setBooks] = useState([]);
    const [purchasedBooks, setPurchasedBooks] = useState([]);

    const userDetails = JSON.parse(localStorage.getItem("session"));
    const userEmail = userDetails?.email;

    useEffect(() => {
        const fetchBooks = async () => {
            try {
                const response = await axios.get(SummaryApi.getAllBooks.url);
                setBooks(response.data.data);
            } catch (err) {
                toast.error('Failed to load books');
                console.error('Error fetching books:', err);
            }
        };

        const fetchPurchasedBooks = async () => {
            try {
                if (userEmail) {
                    const response = await axios.get(`${SummaryApi.getBooksPurchasedByUsers.url}/${userEmail}`);
                    setPurchasedBooks(response.data.data.purchasedBooks);
                }
            } catch (err) {
                console.error('Error fetching purchased books:', err);
            }
        };

        fetchBooks();
        fetchPurchasedBooks();
    }, [userEmail]);

    const HandleSubmit = async (e, book) => {
        e.preventDefault();

        try {
            if (userDetails) {
                let res = await axios.post(SummaryApi.bookPayment.url, {
                    amount: book.price,
                    userId: userDetails._id,
                    bookId: book._id,
                });

                if (res && res.data) {
                    let link = res.data.data.links[1].href;
                    window.location.href = link;
                }
            } else {
                toast.error('Please Login To Buy...');
            }
        } catch (error) {
            console.log('Error during book payment:', error);
        }
    };

    const bookImages = {
        "Beginner Barber Book": BeginnerBarberBook,
        "Fundamentals Book": FundamentalsBook,
        "Module Book": ModuleBook
    };

    const booksPdf = {
        "Beginner Barber Book": "Beginner_Barber_Book.pdf",
        "Module Book": "Module_Book.pdf",
        "Fundamentals Book": "Fundamentals_Book.pdf",
    };

    return (
        <div className="mb-8">
            <h1 className="text-4xl font-bold text-center mb-8">Our Barber Books</h1>

            <div className="flex justify-center items-start bg-gray-100">
                <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 w-full max-w-7xl px-5 py-10">
                    {books.length > 0 ? (
                        books.map((book) => (
                            <div key={book._id} className="bg-white shadow-xl rounded-lg overflow-hidden border border-gray-700">
                                <img
                                    src={bookImages[book.title] || FundamentalsBook}
                                    alt={book.title}
                                    className="w-full h-48 object-cover"
                                />
                                <div className="p-5">
                                    <h2 className="text-xl font-semibold text-gray-800">{book.title}</h2>
                                    <p className="text-gray-600 mt-2">{book.description}</p>
                                    <p className="text-lg font-bold text-gray-800 mt-3">Price: £{book.price}</p>
                                    <div className="mt-4">
                                        {purchasedBooks.some((pBook) => pBook.bookId === book._id) ? (
                                            <a
                                                href={`/pdfs/${booksPdf[book.title]}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="inline-block bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 active:bg-blue-700 focus:ring-2 focus:ring-blue-400"
                                            >
                                                View Book
                                            </a>
                                        ) : (
                                            <button
                                                onClick={(e) => HandleSubmit(e, book)}
                                                className="bg-green-500 hover:bg-green-600 text-white px-6 py-2 rounded-full mt-2 w-full"
                                            >
                                                Buy Now
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p className="text-center text-lg text-gray-500 col-span-full">No books available at the moment.</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default BookList;
