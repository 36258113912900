import React, { useEffect, useState } from "react";
import axios from "axios";
import SummaryApi from "../../common";

const BookSubscribers = () => {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await axios.get(`${SummaryApi.getAllBooksPurchasedByUsers.url}`);
                setUsers(response.data.data);
            } catch (err) {
                console.log(err);
            }
        };
        fetchUsers();
    }, []);

    const formatDate = (isoDate) => {
        const date = new Date(isoDate);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based
        const year = date.getFullYear();

        return `${day}-${month}-${year}`;
    };

    return (
        <div className="container mx-auto p-5">
            <h2 className="text-3xl font-bold mb-5 text-center">Admin Panel - Purchased Books</h2>
            <div className="overflow-x-auto">
                <table className="min-w-full bg-white border border-gray-300 rounded-lg shadow-md">
                    <thead className="bg-gray-800 text-white">
                        <tr>
                            <th className="py-3 px-6 text-left text-sm font-medium">User Name</th>
                            <th className="py-3 px-6 text-left text-sm font-medium">Email</th>
                            <th className="py-3 px-6 text-left text-sm font-medium">Books Purchased</th>
                            <th className="py-3 px-6 text-left text-sm font-medium">Price</th>
                            <th className="py-3 px-6 text-left text-sm font-medium">Purchased Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.length > 0 ? (
                            users.map((user) => (
                                <tr key={user._id} className="border-b hover:bg-gray-100">
                                    <td className="py-3 px-6">{user.name}</td>
                                    <td className="py-3 px-6">{user.email}</td>
                                    <td className="py-3 px-6">
                                        {user.purchasedBooks.map((book) => (
                                            <div key={book.bookId} className="flex justify-between py-1">
                                                <span>{book.title}</span>
                                            </div>
                                        ))}
                                    </td>
                                    <td className="py-3 px-6">
                                        {user.purchasedBooks.map((book) => (
                                            <div key={book.bookId} className="flex justify-between py-1">
                                                <span className="text-blue-600 font-medium">£{book.price}</span>
                                            </div>
                                        ))}
                                    </td>
                                    <td className="py-3 px-6">
                                        {user.purchasedBooks.map((book) => (
                                            <div key={book.bookId} className="flex justify-between py-1">
                                                <span>{formatDate(book.purchasedAt)}</span>
                                            </div>
                                        ))}
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="3" className="py-5 text-center text-gray-500">
                                    No user data found.
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default BookSubscribers;
