import React, { useState } from 'react'
import SummaryApi from '../../common';
import { toast } from 'react-toastify';
import { CgClose } from 'react-icons/cg';

const AdminDeleteProduct = ({ onClose, productData, fetchData }) => {

    const [loading, setLoading] = useState(false);

    const handleDeleteProduct = async () => {
        setLoading(true);

        try {
            const response = await fetch(SummaryApi.deleteProduct.url, {
                method: SummaryApi.deleteProduct.method,
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ productId: productData._id }),
            });

            const responseData = await response.json();

            if (responseData.success) {
                toast.success(responseData?.message || 'Product deleted successfully');
                fetchData();
                onClose();
            } else {
                toast.error(responseData?.message || 'Error deleting product');
            }
        } catch (error) {
            toast.error('An error occurred while deleting the product');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="fixed inset-0 bg-slate-800 bg-opacity-50 z-50 flex items-center justify-center">
            <div className="bg-white p-6 rounded-2xl shadow-2xl w-full max-w-md space-y-4">
                {/* Header Section */}
                <div className="flex justify-between items-center border-b pb-3">
                    <h2 className="font-bold text-xl text-gray-800">Delete Product</h2>
                    <button
                        className="text-2xl text-gray-600 hover:text-red-600"
                        onClick={onClose}
                    >
                        <CgClose />
                    </button>
                </div>

                {/* Confirmation Message */}
                <div className="text-center py-4">
                    <p className="text-lg">
                        Are you sure you want to delete the product: <strong>{productData.productName}</strong>?
                    </p>
                </div>

                {/* Buttons Section */}
                <div className="flex justify-end space-x-4 pt-4">
                    <button
                        onClick={onClose}
                        className="px-4 py-2 bg-gray-400 text-white rounded-xl hover:bg-gray-500 transition-all"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={handleDeleteProduct}
                        disabled={loading}
                        className={`px-4 py-2 rounded-xl text-white ${loading ? 'bg-red-300' : 'bg-red-600 hover:bg-red-700 transition-all'
                            }`}
                    >
                        {loading ? 'Deleting...' : 'Delete'}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default AdminDeleteProduct;
